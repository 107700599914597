.dialogueContent {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
}

.startChallengeExtensionLayout {
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (max-width: 599px) {
  .startChallengeExtensionLayout {
    padding-left: 16px;
    padding-right: 16px;
  }
}
